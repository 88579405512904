
































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import ItemBreadcrumb from '@/components/common/ItemBreadcrumb.vue';
import PropertyOutline from '@/components/property/PropertyOutline.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import ImageZipPreview from '@/components/common/ImageZipPreview.vue';
import StatBtn from '@/components/common/StatBtn.vue';
import FaunaSurveyChip from '@/components/common/FaunaSurveyChip.vue';

import {
  SurveyProperty,
  FaunaSurvey,
  FaunaSurveyStatus,
  FaunaMedia,
  DetectionBbox,
  SNSStatus,
} from '@/api';

import cacheModule from '@/store/Cache';
import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';
import userModule from '@/store/User';

import { numberFormat } from '@/util';

@Component({
  components: {
    ItemBreadcrumb,
    UserAvatar,
    ImageZipPreview,
    StatBtn,
    PropertyOutline,
    TableMenuButton,
    FaunaSurveyChip,
  },
})
export default class SurveyListItem extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  loading = false;

  faunaMediaThumbnails: string[] = [];

  get property() {
    return userModule.propertyBySiteId(this.survey.surveySite.id as string);
  }

  get site() {
    const site =
      this.property &&
      this.property.surveySites.find(s => s.id === this.survey.surveySite.id);

    return site;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isDraft() {
    return this.survey.status === FaunaSurveyStatus.draft;
  }

  get isPublished() {
    return this.survey.status === FaunaSurveyStatus.published;
  }

  get isInProgress() {
    return this.survey.status === FaunaSurveyStatus.inProgress;
  }

  get isComplete() {
    return this.survey.status === FaunaSurveyStatus.assessed;
  }

  get fmCount() {
    return this.survey.faunaMediaCount;
  }

  get surveyTitle() {
    return this.survey.formattedDate;
  }

  get editSurveyLink() {
    return this.isDraft || this.isPublished
      ? {
          name: 'property-surveys-edit',
          params: {
            propertyId: this.property ? this.property.id : undefined,
            surveyId: this.survey.id,
          },
        }
      : {
          name: 'classifier',
          params: {
            propertyId: this.property ? this.property.id : undefined,
            faunaSurveyId: this.survey.id,
          },
        };
  }

  get editSurveyText() {
    return this.isDraft || this.isPublished ? 'Edit survey' : 'Classifier';
  }

  get numberFormat() {
    return numberFormat;
  }

  async getFaunaMediaPreview() {
    try {
      this.loading = true;
      const result = await FaunaMedia.where({
        faunaSurvey: this.survey.id,
        sns_status__in: ['uploaded', 'detected', 'predicted'],
      })
        .per(4)
        .order({ timestamp: 'asc' })
        .all();
      this.faunaMediaThumbnails = result.data.map(fm => fm.thumbnail);
    } catch (e) {
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    userModule.getPropertyBySiteId(this.survey.surveySite.id as string);
    this.getFaunaMediaPreview();
  }
}
