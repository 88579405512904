











































import { Component, Vue, Prop, VModel } from 'vue-property-decorator';

import { numberFormat } from '@/util';

@Component({
  components: {},
})
export default class CompactPagination extends Vue {
  @Prop({ default: false }) readonly stacked: boolean;

  @Prop({ default: false }) readonly dark: boolean;

  @Prop({ required: true }) readonly total: number;

  @Prop({ required: true }) readonly itemsPerPage: number;

  @VModel() page: number;

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${numberFormat(this.total)}`;
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get selectedItem() {
    return this.page;
  }

  set selectedItem(v: number) {
    this.$emit('select', v);
  }

  get items() {
    return Array.from({ length: this.pageCount }, (_, i) => i + 1);
  }

  setPage(p: number) {
    this.page = Math.max(1, Math.min(p, this.pageCount));
  }
}
