





























































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  SurveySite,
  SurveyProperty,
  FaunaSurvey,
  FaunaSurveyStatus,
  FaunaMedia,
  SNSStatus,
} from '@/api';

import dayjs from 'dayjs';

// TODO: handle no sites
import GetStartedSite from '@/components/common/GetStartedSite.vue';

import FaunaMediaGallery from '@/components/upload/FaunaMediaGallery.vue';
import FaunaMediaUploadDialog from '@/components/upload/FaunaMediaUploadDialog.vue';
import FaunaMediaUploader from '@/components/upload/FaunaMediaUploader.vue';
import AlertAccordion from '@/components/common/AlertAccordion.vue';

import StatBtn from '@/components/common/StatBtn.vue';
import DatePicker from '@/components/common/DatePicker.vue';

import { numberFormat } from '@/util';
import snackModule from '@/store/Snack';

import confirmDialog from '@/confirm-dialog';

import {
  BatchUploadResult,
  batchUploader,
} from '@/components/upload/batch-uploader';

@Component({
  components: {
    GetStartedSite,
    FaunaMediaGallery,
    FaunaMediaUploadDialog,
    FaunaMediaUploader,
    AlertAccordion,
    StatBtn,
    DatePicker,
  },
})
export default class SurveyEdit extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  survey: FaunaSurvey | null = null;

  // TODO: handle not found
  notFound = false;

  uploadDialog = false;

  // gallery dialog
  showGalleryDialog = false;

  galleryStatusIn: string[] = [SNSStatus.none, SNSStatus.uploaded];

  galleryMode: 'gallery' | 'table' = 'gallery';

  // delete dialog
  deleteProgress = 100; // 0 - 100

  // the following are the editable for the survey
  selectedSiteId: string | null = null;

  startTimestamp: string | null = null;

  endTimestamp: string | null = null;

  // filenames of problematic fm
  problematicFaunaMedia: string[] = [];

  // the uploader instance
  uploader: BatchUploadResult | null = null;

  // instance for clearing the interval
  predictedCountInterval: number | null = null;

  // last time the predicted count was updated
  lastCountTS = '';

  get surveyId() {
    return this.$route.params.surveyId;
  }

  get createMode() {
    return this.$route.name === 'property-surveys-create';
  }

  get numberFormat() {
    return numberFormat;
  }

  /**
   * how many have been predicted
   */
  get predictedCount() {
    return this.survey ? this.survey.snsStatusPredicted : 0;
  }

  /**
   * how many have been detected
   */
  get detectedCount() {
    return this.survey
      ? this.survey.snsStatusPredicted + this.survey.snsStatusDetected
      : 0;
  }

  /**
   * how many successfully uploaded photos are in the survey
   */
  get totalCount() {
    return this.survey ? this.survey.faunaMediaCount : 0;
  }

  /**
   * whether or not the survey is still processing
   */
  get isProcessing() {
    return this.predictedCount < this.totalCount;
  }

  get isPublished() {
    return this.survey && this.survey.status === FaunaSurveyStatus.published;
  }

  get basicSurveyDataIsValid() {
    return (
      !!this.selectedSiteId && !!this.startTimestamp && !!this.endTimestamp
    );
  }

  get basicSurveyDataIsDirty() {
    if (!this.survey) {
      return false;
    }
    const siteId = this.survey.surveySite ? this.survey.surveySite.id : null;
    return (
      this.selectedSiteId !== siteId ||
      this.startTimestamp !== this.survey.startTimestamp ||
      this.endTimestamp !== this.survey.endTimestamp
    );
  }

  get step1Complete() {
    return !!this.survey && !!this.survey.id;
  }

  get step2Complete() {
    return (
      !!this.survey &&
      this.survey.uploadedWithoutErrorsCount > 0 &&
      this.survey.problematicCount === 0 &&
      !this.uploader
    );
  }

  get step3Complete() {
    return !!this.survey && this.survey.status === FaunaSurveyStatus.published;
  }

  get step4Complete() {
    return (
      this.step1Complete &&
      this.step2Complete &&
      this.step3Complete &&
      !!this.survey &&
      this.totalCount > 0 &&
      this.predictedCount === this.survey.totalCount
    );
  }

  get step5Complete() {
    return false;
  }

  get activeStep() {
    if (this.step4Complete) {
      return 5;
    }
    if (this.step3Complete) {
      return 4;
    }
    if (this.step2Complete) {
      return 3;
    }
    if (this.step1Complete) {
      return 2;
    }
    return 1;
  }

  /**
   * Create a batch uploader to handle the upload of files
   */
  startUpload(files: File[]) {
    if (!this.survey) {
      console.warn('need a survey to start upload');
      return;
    }

    // only accept jpegs
    const filteredFiles = Array.from(files).filter(
      f => f.type === 'image/jpeg',
    );

    // start the uploader
    this.uploader = batchUploader(filteredFiles, this.survey.id as string);
    this.uploader.start();
  }

  /**
   * save basic survey data
   */
  async saveBasicSurveyData() {
    if (!this.survey) {
      console.warn('no survey to save');
      return;
    }

    if (!this.basicSurveyDataIsValid) {
      console.warn('basic data is not valid');
      return;
    }

    try {
      this.survey.surveySite = new SurveySite({
        id: this.selectedSiteId,
      });
      this.survey.startTimestamp = this.startTimestamp as string;
      this.survey.endTimestamp = this.endTimestamp as string;
      await this.survey.save({ with: ['surveySite.id'] });

      this.getUpdatedSurvey();
      snackModule.setSuccess('Survey saved');

      // TODO: repplace url with edit url
      if (this.$route.name === 'property-surveys-create') {
        this.$router.replace({
          name: 'property-surveys-edit',
          params: { surveyId: this.survey.id as string },
        });
      }
      await this.getUpdatedSurvey();
      this.syncInputFields();
      this.getProblematicFaunaMedia();
    } catch (e) {
      snackModule.setError({
        text: 'Could not save survey data',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  /**
   * get the updated survey
   */
  async getUpdatedSurvey() {
    // setup a survey if one doesnt exist
    if (!this.surveyId) {
      if (!this.survey) {
        this.survey = new FaunaSurvey({
          status: FaunaSurveyStatus.draft,
        });
        this.syncInputFields();
      }
      return;
    }

    // fetch the survey from the server
    try {
      const survey = (await FaunaSurvey.find(this.surveyId)).data;
      this.survey = survey;
    } catch (e) {
      snackModule.setError({
        text: 'Error retrieving survey',
        errors: (e as ErrorResponse).response.errors,
      });
      this.notFound = true;
    }
  }

  /**
   * used to sync the input fields with the survey data
   */
  syncInputFields() {
    if (!this.survey) {
      console.warn('cannot sync input fields without survey');
      return;
    }
    this.selectedSiteId =
      this.survey.surveySite && this.survey.surveySite
        ? (this.survey.surveySite.id as string)
        : null;
    this.startTimestamp = this.survey.startTimestamp || null;
    this.endTimestamp = this.survey.endTimestamp || null;
  }

  /**
   * publish the survey
   */
  async publishSurvey() {
    if (!this.survey) {
      console.warn('cannot publish without survey');
      return;
    }
    try {
      this.survey.status = FaunaSurveyStatus.published;
      await this.survey.save();
      snackModule.setSuccess('Survey published');

      await this.getUpdatedSurvey();
      this.syncInputFields();
      this.getProblematicFaunaMedia();
    } catch (e) {
      snackModule.setError({
        text: 'Could not publish survey',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  /**
   * delete images with a pending status
   */
  async deletePending() {
    if (!this.survey) {
      console.warn('cannot delete without survey');
      return;
    }

    const confirm = await confirmDialog();
    if (confirm !== 'confirm') {
      return;
    }

    const pending = (
      await FaunaMedia.per(-1)
        .where({
          faunaSurvey: this.survey.id,
          sns_status: SNSStatus.pending,
        })
        .all()
    ).data;

    const max = pending.length;
    let count = 0;

    const promises = pending.map(async fm => {
      try {
        await fm.destroy();
        return Promise.resolve();
      } catch (e) {
        snackModule.setError({
          text: 'Error deleting file',
          errors: (e as ErrorResponse).response.errors,
        });
        return Promise.resolve();
      } finally {
        count += 1;
        this.deleteProgress = (100 * count) / max;
      }
    });

    await Promise.all(promises);
    snackModule.setSuccess('Images deleted');
    this.getUpdatedSurvey();
  }

  /**
   * get the problematic images
   */
  async getProblematicFaunaMedia() {
    if (!this.survey) {
      console.warn('cannot get fm without survey');
      return;
    }
    try {
      const result = await FaunaMedia.where({
        faunaSurvey: this.survey.id,
        sns_status__in: [SNSStatus.pending],
      })
        .per(20)
        .order({ timestamp: 'asc' })
        .all();

      this.problematicFaunaMedia = result.data.map(fm => fm.filename as string);
    } catch (e) {
      snackModule.setError({
        text: 'Could not get problematic photos',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  showSuccessGallery() {
    this.galleryStatusIn = [
      SNSStatus.none,
      SNSStatus.uploaded,
      SNSStatus.detected,
      SNSStatus.predicted,
    ];
    this.galleryMode = 'gallery';
    this.showGalleryDialog = true;
  }

  showProblematicGallery() {
    this.galleryStatusIn = [
      SNSStatus.pending,
      SNSStatus.detectFailed,
      SNSStatus.predictFailed,
    ];
    this.galleryMode = 'table';
    this.showGalleryDialog = true;
  }

  async mounted() {
    // periodically get the predicted count if still processing
    this.predictedCountInterval = setInterval(() => {
      if (this.isProcessing) {
        this.getUpdatedSurvey();
        this.lastCountTS = dayjs().format('h:mm A');
      }
    }, 5000);

    // no survey - create a new one
    if (!this.surveyId) {
      this.survey = new FaunaSurvey({ status: FaunaSurveyStatus.draft });
      this.syncInputFields();
      return;
    }

    // survye found, lets get all the data
    await this.getUpdatedSurvey();
    this.syncInputFields();
    this.getProblematicFaunaMedia();
  }

  beforeDestroy() {
    // clear the interval that fetches the predicted count
    if (this.predictedCountInterval) {
      clearInterval(this.predictedCountInterval);
    }
  }

  /**
   * Watch for the close of the upload dialog
   * clear the uploader and emit an update
   */
  @Watch('uploadDialog')
  uploadDialogChanged() {
    if (!this.uploadDialog) {
      this.uploader = null;
      this.getUpdatedSurvey();
      this.getProblematicFaunaMedia();
    }
  }

  /**
   * watch for the close of the gallery dialog
   * get updated data in case stuff was deleted
   */
  @Watch('showGalleryDialog')
  showGalleryDialogChanged() {
    if (!this.showGalleryDialog) {
      this.getUpdatedSurvey();
      this.getProblematicFaunaMedia();
    }
  }
}
