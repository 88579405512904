import { Model, Attr, BelongsTo, HasOne, HasMany } from 'spraypaint';
import { FaunaMediaTag, FaunaSurvey, Media, DetectionBbox } from '@/api';
import dayjs from 'dayjs';
import ApplicationRecord from '../ApplicationRecord';
import UserFavourite from './UserFavourite';

export enum SNSStatus {
  pending = 'pending',
  uploaded = 'uploaded',
  detected = 'detected',
  predicted = 'predicted',
  detectFailed = 'detect_failed',
  predictFailed = 'predict_failed',
  none = 'none',
}

@Model()
export default class FaunaMedia extends ApplicationRecord {
  static jsonapiType = 'fauna-media';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Timestamp',
        value: 'timestamp',
      },
    ];
  }

  static scopeFactory() {
    return FaunaMedia.includes([]);
  }

  // send multipart to image

  @Attr() createdAt: string;

  @Attr() timestamp: string;

  @Attr() comment: string;

  // conflict between user and ai tag
  @Attr() readonly hasConflict: boolean;

  // ai detection status (none, pending, uploaded, detected, predicted)
  @Attr() snsStatus: SNSStatus;

  // aggregate counts for detection bbox tag status
  // humnas / vehicles not in this count by default
  // isBlank also ignored in these counts
  @Attr() readonly tagStatusNone: number;

  @Attr() readonly tagStatusConflict: number;

  @Attr() readonly tagStatusAgreed: number;

  @Attr() readonly tagStatusResolved: number;

  @Attr() readonly isBlank: boolean;

  // s3 path for original image and thumbnail
  @Attr() imagePath: string | null;

  @Attr() thumbnailPath: string | null;

  // TODO: need to get this right
  @Attr() noFauna: boolean; // this is writable (i.e. i see no animal)

  @BelongsTo() faunaSurvey: FaunaSurvey;

  @HasMany() faunaMediaTags: FaunaMediaTag[];

  @HasMany() userFavourites: UserFavourite[];

  // @HasOne() media: Media; // this will be removed

  @HasMany(DetectionBbox) detectionBboxes: DetectionBbox[];

  adjustedFavouriteCount?: number;

  get bboxCount() {
    return (
      this.tagStatusNone +
      this.tagStatusConflict +
      this.tagStatusAgreed +
      this.tagStatusResolved
    );
  }

  get favouriteCount() {
    return this.adjustedFavouriteCount !== undefined
      ? this.adjustedFavouriteCount
      : this.userFavourites.length;
  }

  get formattedDate() {
    return dayjs(this.timestamp).format('MMM D, YYYY');
  }

  get formattedTime() {
    return dayjs(this.timestamp).format('h:mm:ss a');
  }

  get formattedTimestamp() {
    return dayjs(this.timestamp).format('MMM D, YYYY • h:mm:ss a');
  }

  get filename() {
    return this.imagePath ? this.imagePath.split('/').pop() : '';
  }

  get isResolved() {
    return this.tagStatusAgreed + this.tagStatusResolved === this.bboxCount;
  }

  get thumbnail() {
    return this.thumbnailPath
      ? `${this.klass.url(this.id)}serve/thumbnail/`
      : '';
  }

  get serve() {
    return this.imagePath ? `${this.klass.url(this.id)}serve/` : '';
  }

  get helpfulSnsStatus() {
    switch (this.snsStatus) {
      case SNSStatus.pending:
        return 'File missing';
      case SNSStatus.uploaded:
        return 'Uploaded';
      case SNSStatus.detected:
        return 'Detected';
      case SNSStatus.predicted:
        return 'Predicted';
      case SNSStatus.detectFailed:
        return 'Detection failure';
      case SNSStatus.predictFailed:
        return 'Prediction failure';
      default:
        return 'none';
    }
  }
}
