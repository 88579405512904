import { Model, Attr, BelongsTo } from 'spraypaint';
import { FaunaTagGroup } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FaunaTag extends ApplicationRecord {
  static jsonapiType = 'fauna-tags';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Group',
        value: 'faunaTagGroup.name',
      },
      {
        text: 'Requires disease status',
        value: 'prettyDiseaseStatus',
      },
      {
        text: 'Shortcut key',
        value: 'keycode',
      },
    ];
  }

  static scopeFactory() {
    return FaunaTag.includes(['faunaTagGroup']).order({
      faunaTagGroup: 'asc',
      name: 'asc',
    });
  }

  @Attr() name: string;

  @Attr() shortDescription: string;

  @Attr() description: string;

  @Attr() createdAt: string;

  @Attr() diseaseStatusRequired: boolean;

  @Attr() keycode: string;

  @Attr() code: string;

  @Attr() invasive: boolean;

  // upload to "uploadAvatar"
  @Attr() avatar: string;

  // upload to "uploadCoverPhoto"
  @Attr() coverPhoto: string;

  @BelongsTo() faunaTagGroup: FaunaTagGroup;

  get prettyDiseaseStatus() {
    return this.diseaseStatusRequired ? 'Yes' : 'No';
  }

  get avatarUrl() {
    return this.avatar
      ? this.avatar
      : `https://api.dicebear.com/7.x/pixel-art/svg?seed=tag${this.id}&scale=70`;
  }

  get coverPhotoUrl() {
    return this.coverPhoto
      ? this.coverPhoto
      : `https://api.dicebear.com/7.x/pixel-art/svg?seed=tag${this.id}&scale=70`;
  }
}
