































































import { Component, Vue, InjectReactive } from 'vue-property-decorator';

import UserAvatar from '@/components/common/UserAvatar.vue';
import Classifier from '@/components/classifier/Classifier.vue';

@Component({
  components: {
    UserAvatar,
  },
})
export default class ClassifierImageInfo extends Vue {
  @InjectReactive() classifier: Classifier;

  get currentPrettyStatus() {
    return this.classifier.faunaSurvey
      ? this.classifier.faunaSurvey.prettyStatus
      : 'Unknown';
  }
}
