var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center gap-4"},[_c('user-avatar',{attrs:{"highlight-me":true,"user":_vm.currentUser,"dark":true,"name":'tooltip'},scopedSlots:_vm._u([{key:"tooltip",fn:function(ref){
var user = ref.user;
return [(user)?_c('div',[_vm._v(" Logged in as "),_c('strong',[_vm._v(_vm._s(user.name))])]):_vm._e()]}}])}),(_vm.classifier.isAdmin)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","dark":"","color":"primary","disabled":_vm.classifier.loading},on:{"click":function($event){_vm.classifier.showChangeStatusDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-shield-account")]),_vm._v(" Change Status ")],1)]}}],null,false,3137043374)},[_c('div',{staticClass:"text-center"},[_vm._v(" As an admin, you have the ability to set the status of this survey without following the usual flow. ")])]):_vm._e(),(!_vm.classifier.isAdmin && _vm.classifier.canPublish)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","dark":"","color":"primary","disabled":_vm.classifier.loading},on:{"click":_vm.classifier.publish}},'v-btn',attrs,false),on),[_vm._v(" Publish ")])]}}],null,false,1169077757)},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Click here when you've uploaded all of your photos. ")]),_vm._v(" Once published, the survey will become available for tagging. ")])]):_vm._e(),(!_vm.classifier.isAdmin && _vm.classifier.canStartTagging)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","dark":"","color":"primary","disabled":_vm.classifier.loading},on:{"click":_vm.classifier.startTagging}},'v-btn',attrs,false),on),[_vm._v(" Start Tagging ")])]}}],null,false,2441194781)},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Click here to start tagging ")]),_c('div',[_vm._v(" Take your time – you can come and go as you wish – but the aim is to tag all of the photos in this survey. When you're finished, mark it as complete and we'll able to use your data for our reserach. ")])])]):_vm._e(),(!_vm.classifier.isAdmin && _vm.classifier.canComplete)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","large":"","dark":"","color":"green","disabled":_vm.classifier.loading},on:{"click":_vm.classifier.markAsComplete}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Mark as Complete ")],1)]}}],null,false,1455413768)},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("All done?")]),_c('div',[_vm._v(" Make sure you've tagged all of your photos before hitting this – once complete, you won't be able to make any more changes. ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }