import { debounce } from 'lodash';
import imageCompression from 'browser-image-compression';
import { FaunaSurveyPresignImages } from '@/api';

interface SingleUploadListeners {
  preparestart: Array<(filekey: string) => void>;
  loadstart: Array<(filekey: string) => void>;
  progress: Array<(filekey: string) => void>;
  loadend: Array<(filekey: string) => void>;
  error: Array<(filekey: string) => void>;
  abort: Array<(filekey: string) => void>;
  timeout: Array<(filekey: string) => void>;
  statuschange: Array<(filekey: string) => void>;
}
interface BatchUploadListeners {
  preparestart: Array<() => void>;
  queuestart: Array<() => void>;
  queueprogress: Array<() => void>;
  queuecomplete: Array<() => void>;
}

/**
 * return type for single upload
 */
export interface SingleUploadResult {
  key: string;
  url: string;
  addListener: (
    type: keyof SingleUploadListeners,
    listener: (filekey: string) => void,
  ) => void;
  progress: () => number;
  error: () => Event | null;
  previewUrl: () => string | null;
  status: () => 'pending' | 'preparing' | 'uploading' | 'complete' | 'error';
  start: () => void;
  retry: () => void;
  abort: () => void;
}

/**
 * return type for batch upload
 */
export interface BatchUploadResult {
  addListener: (type: keyof BatchUploadListeners, listener: () => void) => void;
  start: () => void;
  retrySingle: (key: string) => void;
  retryAll: () => void;
  clearCompleted: () => void;
  totalCompleted: () => number;
  totalError: () => number;
  queue: () => SingleUploadResult[];
  status: () => 'pending' | 'preparing' | 'uploading' | 'complete' | 'error';
  log: () => string[];
}

/**
 * Handle the upload of a single file
 * @param file - a single file to upload
 * @param url - the presigned url to upload the file to
 * @param options - optional options
 * @returns - a single upload result
 */
export function singleUpload(
  file: File,
  url: string,
  options?: { listeners?: Partial<SingleUploadListeners> },
): SingleUploadResult {
  const listeners: SingleUploadListeners = {
    preparestart: [],
    loadstart: [],
    progress: [],
    loadend: [],
    error: [],
    abort: [],
    timeout: [],
    statuschange: [],
  };
  if (options && options.listeners) {
    Object.assign(listeners, options.listeners);
  }

  let xhr: XMLHttpRequest | null = null;
  let compressedFile: File | null = null;

  let progress = 0;
  let status: 'pending' | 'preparing' | 'uploading' | 'complete' | 'error' =
    'pending';
  let error: Event | null = null;
  let previewUrl: string | null = null;

  // set the status and trigger the status change event
  const setStatus = (s: typeof status) => {
    status = s;
    listeners.statuschange.forEach(l => l(file.name));
  };

  // upload starts event
  const loadstartEvent = (event: ProgressEvent) => {
    setStatus('uploading');
    listeners.loadstart.forEach(l => l(file.name));
  };

  // progress event
  const progressEvent = (event: ProgressEvent) => {
    progress = (event.loaded / event.total) * 100;
    listeners.progress.forEach(l => l(file.name));
  };

  // upload is finished event
  const loadendEvent = (event: ProgressEvent) => {
    if (status === 'error') {
      return;
    }
    progress = 100;
    setStatus('complete');
    listeners.loadend.forEach(l => l(file.name));
  };

  // XHR done
  const readystatechangeEvent = () => {
    if (xhr && xhr.readyState === XMLHttpRequest.DONE) {
      // console.warn('readystatechange', file.name, progress, xhr.status, status);
      if (xhr.status === 0 || xhr.status === 200) {
        console.log(
          'success',
          file.name,
          xhr.responseText,
          progress,
          xhr.status,
          xhr,
        );
      } else {
        console.warn(
          'failed',
          file.name,
          xhr.responseText,
          progress,
          xhr.status,
          xhr,
        );
      }
    }
  };

  // error event
  function errorEvent(event: Event) {
    console.warn('error', file.name, event, xhr);
    setStatus('error');
    error = event;
    listeners.error.forEach(l => l(file.name));
  }

  function createXHR() {
    xhr = new XMLHttpRequest();
    xhr.timeout = 60000; // 60 seconds

    xhr.addEventListener('loadstart', loadstartEvent);
    xhr.addEventListener('progress', progressEvent);
    xhr.addEventListener('loadend', loadendEvent);
    xhr.addEventListener('error', errorEvent);
    xhr.addEventListener('abort', errorEvent);
    xhr.addEventListener('timeout', errorEvent);
    xhr.onreadystatechange = readystatechangeEvent;

    xhr.open('PUT', url, true);
    xhr.send(compressedFile);

    return xhr;
  }

  const prepare = async () => {
    // if the file has already been prepared, return
    if (compressedFile) {
      return;
    }

    // set status and trigger the preparestart event
    setStatus('preparing');
    listeners.preparestart.forEach(l => l(file.name));

    // compress the image
    const t0 = performance.now();
    compressedFile = await imageCompression(file, {
      maxWidthOrHeight: 2000,
      preserveExif: true,
    });
    const t1 = performance.now();
    // console.log(
    //   `compressed ${file.name} in ${t1 - t0}ms`,
    //   `from ${file.size / 1024 / 1024}MB`,
    //   `to ${compressedFile.size / 1024 / 1024}MB`,
    // );

    // create the preview image
    const previewFile = await imageCompression(file, {
      maxWidthOrHeight: 200,
    });
    const t2 = performance.now();
    // console.log(
    //   `compressed ${file.name} in ${t2 - t1}ms`,
    //   `from ${file.size / 1024 / 1024}MB`,
    //   `to ${previewFile.size / 1024 / 1024}MB`,
    // );

    // set the preview url
    previewUrl = URL.createObjectURL(previewFile);
  };

  // prepare the file, create the xhr and start the upload
  const start = async () => {
    if (status !== 'pending') {
      console.warn('already started');
      return;
    }
    await prepare();
    createXHR();
  };

  const abort = () => {
    if (xhr) {
      xhr.abort();
    }
    setStatus('error');
  };

  const retry = () => {
    if (status !== 'error') {
      console.warn('not in error state');
      return;
    }

    // reset the status and the progress
    setStatus('pending');
    progress = 0;
  };

  const addListener = (
    type: keyof SingleUploadListeners,
    listener: (filekey: string) => void,
  ) => {
    listeners[type].push(listener);
  };

  return {
    key: file.name,
    url,
    addListener,
    progress: () => progress,
    error: () => error,
    previewUrl: () => previewUrl,
    status: () => status,
    start,
    retry,
    abort,
  };
}

/**
 * Handle the upload of multiple files
 * @param files - an array of files to upload
 * @param signatureMap - a map of filenames to presigned urls
 * @returns - a batch upload result
 */
export function batchUploader(
  files: File[],
  surveyId: string,
  options?: { listeners?: Partial<BatchUploadListeners> },
): BatchUploadResult {
  const listeners: BatchUploadListeners = {
    preparestart: [],
    queuestart: [],
    queueprogress: [],
    queuecomplete: [],
  };
  if (options && options.listeners) {
    Object.assign(listeners, options.listeners);
  }

  const maxConsecutiveUploads = 5;

  let status: 'pending' | 'preparing' | 'uploading' | 'complete' | 'error' =
    'pending';
  let totalCompleted = 0; // number of successful files uploaded
  let totalError = 0; // number of failed files uploaded
  let queue: SingleUploadResult[] = [];
  const log: string[] = [];

  const processQueue = debounce(() => {
    let inProgress = 0;
    let completed = 0;
    let error = 0;

    // count the number of in progress and completed uploads
    queue.forEach(upload => {
      const itemStatus = upload.status();
      if (itemStatus === 'complete') {
        completed += 1;
      }
      if (itemStatus === 'preparing' || itemStatus === 'uploading') {
        inProgress += 1;
      }
      if (itemStatus === 'error') {
        error += 1;
      }
    });
    totalCompleted = completed;
    totalError = error;

    log.push(
      `queue: ${queue.length} inProgress: ${inProgress} error: ${error} completed: ${completed}`,
    );

    listeners.queueprogress.forEach(l => l());

    // if all files have been uploaded successfully
    if (completed === queue.length) {
      status = 'complete';
      log.push('queue complete');
      listeners.queuecomplete.forEach(l => l());
      return;
    }
    // if all files have been uploaded with errors
    if (completed + error === queue.length) {
      status = 'error';
      log.push('queue error');
      listeners.queuecomplete.forEach(l => l());
      return;
    }

    // look at the queue and see if we can start any uploads
    let i = 0;
    while (inProgress < maxConsecutiveUploads && i < queue.length) {
      const upload = queue[i];
      if (upload.status() === 'pending') {
        log.push(`inProgress: ${inProgress} / starting ${upload.key}`);
        upload.start();
        inProgress += 1;
      }
      i += 1;
    }
  }, 250);

  const prepareQueue = async () => {
    status = 'preparing';
    listeners.preparestart.forEach(l => l());

    // get the presigned urls
    const presign = new FaunaSurveyPresignImages({
      faunaSurvey: surveyId,
      images: files.map(f => f.name),
    });
    await presign.save();

    // create the queue
    queue = files
      .filter(
        f => f.name in presign.signatureMap && !!presign.signatureMap[f.name],
      )
      .map(f => {
        const url = presign.signatureMap[f.name];
        if (!url) {
          throw new Error(`No signature for ${f.name}`);
        }
        return singleUpload(f, url, {
          listeners: {
            statuschange: [
              () => {
                processQueue();
              },
            ],
          },
        });
      });
  };

  const start = async () => {
    log.push('start');

    if (status !== 'pending') {
      log.push('WARN: already started');
      return;
    }

    if (!queue.length) {
      await prepareQueue();
    }

    status = 'uploading';
    listeners.queuestart.forEach(l => l());
    processQueue();
  };

  const retrySingle = (key: string) => {
    const single = queue.find(item => item.key === key);
    if (!single) {
      console.warn(`no item with key ${key}`);
      return;
    }
    single.retry();

    if (status !== 'uploading') {
      status = 'uploading';
      listeners.queuestart.forEach(l => l());
    }

    processQueue();
  };

  const retryAll = () => {
    if (status !== 'error') {
      log.push('WARN: not in error state');
      return;
    }

    queue.forEach(item => {
      if (item.status() === 'error') {
        item.retry();
      }
    });

    status = 'uploading';
    listeners.queuestart.forEach(l => l());
    processQueue();
  };

  const clearCompleted = () => {
    let i = 0;
    while (i < queue.length) {
      if (queue[i].status() === 'complete') {
        queue.splice(i, 1);
      } else {
        i += 1;
      }
    }
  };

  const addListener = (
    type: keyof BatchUploadListeners,
    listener: () => void,
  ) => {
    listeners[type].push(listener);
  };

  return {
    addListener,
    start,
    retrySingle,
    retryAll,
    clearCompleted,
    totalCompleted: () => totalCompleted,
    totalError: () => totalError,
    queue: () => queue,
    status: () => status,
    log: () => log,
  };
}
