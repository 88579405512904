



























import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';

import { FaunaMedia } from '@/api';

import snackModule from '@/store/Snack';

@Component({
  components: {},
})
export default class BboxDialog extends Vue {
  @Prop({ default: null }) readonly item: FaunaMedia | null;

  @VModel() show: boolean;

  itemToSave = new FaunaMedia();

  async saveComment() {
    if (!this.itemToSave.id) {
      console.warn('fauna media needs an id to save comment');
      return;
    }

    try {
      await this.itemToSave.save();
      snackModule.setSuccess('Comment saved');
      this.show = false;
    } catch (e) {
      snackModule.setError({
        text: 'Could not save comment',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  @Watch('show')
  showChanged() {
    if (this.show && this.item) {
      this.itemToSave = this.item.dup();
    } else {
      this.itemToSave = new FaunaMedia();
    }
  }
}
