











































































































































import { Component, Vue, InjectReactive } from 'vue-property-decorator';

import Classifier from '@/components/classifier/Classifier.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';

import authModule from '@/store/Auth';

@Component({
  components: {
    UserAvatar,
  },
})
export default class ClassifierImageInfo extends Vue {
  @InjectReactive() classifier: Classifier;

  get currentUser() {
    return authModule.user;
  }
}
