



















































































import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  SurveyProperty,
  SurveySite,
  FaunaSurvey,
  FaunaMedia,
  DetectionBbox,
} from '@/api';

import UserAvatar from '@/components/common/UserAvatar.vue';

@Component({
  components: {
    UserAvatar,
  },
})
export default class ItemBreadcrumb extends Vue {
  @Prop({ default: null }) readonly property: SurveyProperty | null;

  @Prop({ default: null }) readonly site: SurveySite | null;

  @Prop({ default: null }) readonly survey: FaunaSurvey | null;

  @Prop({ default: null }) readonly faunaMedia: FaunaMedia | null;

  @Prop({ default: null }) readonly bbox: DetectionBbox | null;

  @Prop({ default: false }) readonly showUser: boolean;
}
