

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import v8n from 'v8n';
import { FaunaTagGroup } from '@/api/';
import snackModule from '@/store/Snack';

@Component({ components: { PageHeader, EmptyItem } })
export default class AdminFaunaTagGroupsSingle extends Vue {
  /* Attributes */
  title: string;

  item: FaunaTagGroup | null = null;

  notFound = false;

  loading = false;

  valid = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  optionalString = [
    (v: string) =>
      v8n()
        .optional(v8n().string(), true)
        .test(v),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    console.log(this.$route.name);
    return this.$route.name === 'admin-fauna-tag-groups-new';
  }

  /* Functions */
  async save() {
    if (!this.item) return;
    try {
      this.loading = true;
      await this.item.save();
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaTagGroups.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Fauna Tag Group';
      this.item = new FaunaTagGroup();
    } else {
      this.title = 'Edit Fauna Tag Group';
      try {
        this.item = (await FaunaTagGroup.find(this.itemId)).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }
}
