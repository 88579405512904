var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classifier-image-info"},[_c('v-spacer'),_c('div',[_c('div',{staticClass:"d-flex align-center justify-center gap-x-8 gap-y-3 flex-wrap pa-3"},[_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-land-plots")]),_c('div',[_vm._v(" "+_vm._s(_vm.classifier.property.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-map-marker-outline")]),_c('div',[_vm._v(" "+_vm._s(_vm.classifier.breadcrumbSite ? _vm.classifier.breadcrumbSite.name : '')+" ")])],1),_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-image-multiple-outline")]),_c('div',[_vm._v(" Survey #"+_vm._s(_vm.classifier.breadcrumbSurvey ? _vm.classifier.breadcrumbSurvey.id : '')+" ")])],1),_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-camera-outline")]),_c('div',[_vm._v("Image #"+_vm._s(_vm.item.id))])],1),_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-clock-outline")]),_c('div',[_vm._v("Taken "+_vm._s(_vm.item.formattedTimestamp))])],1),(_vm.item.bboxCount)?_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-tag-outline")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.bboxCount - _vm.item.tagStatusNone)+" of "+_vm._s(_vm.item.bboxCount)+" Classified ")]),(_vm.item.tagStatusConflict)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,1173948929)},[_c('div',{staticClass:"text-center"},[_vm._v(" 1 or more detections need an expert opinion ")])]):_vm._e(),(_vm.item.tagStatusResolved)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"220"}},[_c('v-template',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cyan","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-school ")])]}}],null,false,3785362977)}),_vm._v(" 1 or more detections have been resolved by an expert ")],1):_vm._e(),(_vm.item.bboxCount && _vm.item.isResolved)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,false,2290326242)},[_vm._v(" All detections have been resolved ")]):_vm._e()],1):_c('div',{staticClass:"d-flex align-center justify-center gap-2"},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-border-none-variant")]),_c('div',[_vm._v(" Empty Photo ")])],1)])]),_c('v-spacer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }