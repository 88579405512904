var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-container"},[(_vm.property)?_c('div',{staticClass:"breadcrumb-item"},[(_vm.showUser)?_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"highlight-me":true,"user":_vm.property.owner,"name":"tooltip"}}):_vm._e(),(_vm.property)?_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'property-overview',
        params: { propertyId: _vm.property.id },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-land-plots")]),_c('span',[_vm._v(_vm._s(_vm.property.name))])],1):_vm._e()],1):_vm._e(),(_vm.site)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'property-sites',
        params: { propertyId: _vm.site.id },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker-outline")]),_c('span',[_vm._v(_vm._s(_vm.site.name))])],1)],1):_vm._e(),(_vm.property && _vm.survey)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
        params: { propertyId: _vm.property.id, faunaSurveyId: _vm.survey.id },
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-multiple-outline")]),_c('span',[_vm._v("Survey #"+_vm._s(_vm.survey.id))])],1)],1):_vm._e(),(_vm.faunaMedia)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-camera-outline")]),_c('span',[_vm._v("Image #"+_vm._s(_vm.faunaMedia.id))])],1)],1):_vm._e(),(_vm.bbox)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-creation")]),_c('span',[_vm._v("Detection #"+_vm._s(_vm.bbox.id))])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }