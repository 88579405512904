var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"twelve-col-layout twelve-col-layout--gap-sm mb-8"},[_c('stat-tile',{staticClass:"col-span-3",attrs:{"title":"Surveys created","subtitle":((_vm.property.surveyAssessed) + " completed"),"value":_vm.property.surveyAssessed,"max":_vm.property.surveyCount,"to":{
      name: 'property-surveys',
      params: { propertyId: _vm.property.id },
    }}}),_c('stat-tile',{staticClass:"col-span-3",attrs:{"title":"Photos uploaded","subtitle":((_vm.numberFormat(_vm.property.faunaMediaCount)) + " uploaded"),"value":_vm.property.faunaMediaCount,"max":_vm.property.faunaMediaCount,"to":{
      name: 'property-tags',
      params: { propertyId: _vm.property.id },
    }}}),_c('stat-tile',{staticClass:"col-span-3",attrs:{"title":"Animals found","subtitle":((_vm.numberFormat(
        _vm.property.totalBboxCount - _vm.property.tagStatusNone
      )) + " identified"),"value":_vm.property.totalBboxCount - _vm.property.tagStatusNone,"max":_vm.property.totalBboxCount,"to":{
      name: 'property-tags',
      params: { propertyId: _vm.property.id },
    }}}),_c('stat-tile',{staticClass:"col-span-3",attrs:{"title":"Conflicting tags","subtitle":((_vm.numberFormat(_vm.property.tagStatusResolved)) + " resolved"),"value":_vm.property.tagStatusResolved,"max":_vm.property.totalConflictCount,"to":{
      name: 'property-tags',
      params: { propertyId: _vm.property.id },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }